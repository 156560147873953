<template>
  <b-row>
    <b-col sm="12">

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col v-if="php">
            <h4 id="traffic" class="card-title mb-0">{{this.php.name}}</h4>            
            <div class="small text-muted">{{this.php.observations}}</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer" class="card-view-php-custom">
        <b-row v-if="php" class="p-0">
          <b-col md="12">
            <b-embed
              type="iframe"      
              :src="this.php.external_url"
              allowfullscreen
            ></b-embed>    
          </b-col>
        </b-row>            

        <template v-slot:footer>
          <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)" class="pull-left">
            <i class="fa fa-angle-double-left"></i>
            Volver
          </b-button>
        </template>        
      </b-card>      
     
    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  
  export default {       
    data: () => {
      return {      
        access: {
          module_id: Modules.PHP,
          profile_id: Profiles.PERSONAL,
          view_reference: 'viewPHPCustom',
          elements: {}
        },  
        php: null,                   
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {              
      this.load()
    },
    methods: {  
      load() {        
        if(!this.$route.params.id) {
          this.$router.go(-1)
        }

        const id = this.$route.params.id  
        var result = serviceAPI.mostrarPHP(id)

        result.then((response) => {
          var data = response.data
          
          this.php = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });                
      },   
    }      
  }
</script>
<style scoped>
  .card-view-php-custom .card-body {
    padding: 0 !important;
  }
</style>